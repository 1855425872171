import * as React from "react";
import Dialog from "@mui/material/Dialog";
import { ChangePassword, Delete, Logout } from "./modals";
import { AddCategory, AddSubCategory } from "../../pages/Category/modal";
import AddAdmin from "../../pages/AdminManagement/modal/AddAdmin";
import CountryAdd from "../../pages/Location/Country/Modal/CountryAdd";
import StateAdd from "../../pages/Location/State/Modal/StateAdd";
import CityAdd from "../../pages/Location/City/Modal/CityAdd";
import EditAddress from "../../pages/User/modal/EditAddress";
import AddAppointment from "../../pages/Appointment/AddAppointment";
import TaxAdd from "../../pages/Tax/modal/TaxDetails";
import TaxDetails from "../../pages/Tax/modal/TaxDetails";
import StatusChange from "../../pages/Appointment/modal/StatusChange";
import PaymentModal from "../../pages/PurchaseOrder/modal/PaymentModal";
import { AddDiscount } from "../../pages/Coupons/modal";
import UploadProduct from "../../pages/Product/modal/UploadProduct";
import OrderStatusChnages from "../../pages/Appointment/modal/OrderStatusChnages";
import AddBrand from "../../pages/Brand/AddBrand";
import AddUom from "../../pages/uom/modal/UomAdd";
import AddFeature from "../../pages/feature/modal/FeatureAdd";

export default function CommonModal({
  handleClose,
  data1,
  userData,
  removeUser,
  selectUser,
  multiselectSelectUser,
  modalOpen,
  isLoading,
  handelClick,
  formik,
  files,
  setFiles,
  data,
  categoryData,
  stateList,
  callBackHandler,
}) {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="Common_modal"
      open={modalOpen.open}
    >
      {modalOpen.currentComponent === "changePassword" && (
        <ChangePassword handleClose={handleClose} modalOpen={modalOpen} />
      )}
      {["delete", "status", "changepassword", "sponsored", "b2bStatus"].includes(
        modalOpen.currentComponent
      ) && (
          <Delete
            handleClose={handleClose}
            modalOpen={modalOpen}
            handelClick={handelClick}
          />
        )}
      {modalOpen.currentComponent === "logout" && (
        <Logout
          handleClose={handleClose}
          handelClick={handelClick}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "addCategory" && (
        <AddCategory
          handleClose={handleClose}
          modalOpen={modalOpen}
          handelClick={handelClick}
          formik={formik}
          files={files}
          setFiles={setFiles}
        />
      )}

      {modalOpen.currentComponent === "addBrand" && (
        <AddBrand
          handleClose={handleClose}
          modalOpen={modalOpen}
          handelClick={handelClick}
          formik={formik}
          files={files}
          setFiles={setFiles}
        />
      )}
      {modalOpen.currentComponent === "addSubCategory" && (
        <AddSubCategory
          handleClose={handleClose}
          categoryData={categoryData}
          modalOpen={modalOpen}
          handelClick={handelClick}
          formik={formik}
          files={files}
          setFiles={setFiles}
        />
      )}

      {modalOpen.currentComponent === "addAdmin" && (
        <AddAdmin
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "addCountry" && (
        <CountryAdd
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "addState" && (
        <StateAdd
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "addCity" && (
        <CityAdd
          data={data}
          stateList={stateList}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "editAddress" && (
        <EditAddress
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "addAppointment" && (
        <AddAppointment
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
          data={data}
        />
      )}
      {modalOpen.currentComponent === "taxAdd" && (
        <TaxAdd
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "taxDetail" && (
        <TaxDetails
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "ApoinmentstatusChange" && (
        <StatusChange
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}
      {modalOpen.currentComponent === "OrderStatusChange" && (
        <OrderStatusChnages
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          formik={formik}
          modalOpen={modalOpen}
          callBackHandler={callBackHandler}
        />
      )}
      {modalOpen.currentComponent === "payment" && (
        <PaymentModal
          data={data}
          handleClose={handleClose}
          isLoading={isLoading}
          formik={formik}
          modalOpen={modalOpen}
        />
      )}

      {modalOpen.currentComponent === "addDiscount" && (
        <AddDiscount
          handleClose={handleClose}
          removeUser={removeUser}
          userData={userData}
          selectUser={selectUser}
          multiselectSelectUser={multiselectSelectUser}
          categoryData={categoryData}
          modalOpen={modalOpen}
          handelClick={handelClick}
          formik={formik}
          files={files}
          setFiles={setFiles}
        />
      )}
      {modalOpen.currentComponent === "uploadProduct" && (
        <UploadProduct
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
          files={files}
          setFiles={setFiles}
          handelClick={handelClick}
        />
      )}

      {modalOpen.currentComponent === "uom" && (
        <AddUom
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
          files={files}
          setFiles={setFiles}
          handelClick={handelClick}
        />
      )}

      {modalOpen.currentComponent === "feature" && (
        <AddFeature
          data={data}
          handleClose={handleClose}
          formik={formik}
          modalOpen={modalOpen}
          files={files}
          setFiles={setFiles}
          handelClick={handelClick}
        />
      )}
    </Dialog>
  );
}
