import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableCell,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Box,
  Switch,
  Chip,
} from "@mui/material";
import {
  Button,
  Heading,
  MenuButton,
  Modal,
  Select,
} from "../../components/Common";
import { useNavigate } from "react-router-dom";
import { Pagination, Search } from "../../components/Common/Table";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { MdMoreVert } from "react-icons/md";
import { IoAdd, IoEyeOutline } from "react-icons/io5";
import { HiOutlineTrash } from "react-icons/hi";
import { SalesOrderListData } from "../../Service/salesOrder";
import moment from "moment";
import { notificationSuccess } from "../../state/Action/NotificationAction";
import { useDispatch } from "react-redux";
import { VscFilter } from "react-icons/vsc";
import TableRowsLoader from "../../components/Common/Loader/Skeleton";
import { filterPurchaseOrderInitialValues } from "../../helper/initialValues";
import { useFormik } from "formik";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";
import { saleOrderStatus } from "../../helper/Helper";
import SalesOrderFilter from "../SalesOrder/SalesOrderFilter";

const SalesInvoice = () => {
  const navigate = useNavigate();
  const [purchaseOrderData, setPurchaseOrderData] = useState([]);
  const [search, setSearch] = useState("");
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [value, setValue] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = useState({
    open: false,
    currentComponent: "",
    para: "",
    head: "",
    id: "",
  });
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [sortDate, setSortDate] = useState(false);
  const [shortKey, setShortKey] = useState("category");
  const [sortBy, setSortBy] = useState(false);
  const [orderStatus, setOrderStatus] = useState("");

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const getPurchaseList = async () => {
    setPurchaseOrderData([]);
    let query = `?search=${search}&page=${page + 1
      }&perPageSize=${rowsPerPage}&orderStatus=${orderStatus}&orderBy=${shortKey}&sortFlag=${sortBy}`;
    let res = await SalesOrderListData(query);
    if (res?.status) {
      setPurchaseOrderData(res?.data);
      formik.setFieldValue("formPrice", res?.minPrice);
      formik.setFieldValue("toPrice", res?.maxPrice);
      value.length === 0 && setValue([res?.minPrice, res?.maxPrice]);

      setTotalData(res?.total);
    } else {
      setPurchaseOrderData(null);
    }
  };

  const formik = useFormik({
    initialValues: filterPurchaseOrderInitialValues,
    onSubmit: async (value) => {
      console.log("value", value);
      setOrderStatus(value.orderStatus);
      setOpenDrawer(false);
      // getPurchaseList(value.orderStatus);
    },
  });

  const callBackHandler = () => {
    getPurchaseList();
  };

  useEffect(() => {
    getPurchaseList();
  }, [search, page, rowsPerPage, sortBy, shortKey, orderStatus]);

  const handleSortClick = (name, by) => {
    switch (name) {
      case "purchaseDate":
        setSortBy(!by);
        setSortDate((current) => !current);
        setShortKey("purchaseDate");
        break;

      default:
        setSortBy(!sortDate);
        setSortDate(false);
        setShortKey("purchaseDate");
        break;
    }
  };

  return (
    <div>
      <TableContainer component={Paper} className='tablebodyscrooling'>
        <Stack
          p={"12px"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Search search={search} setSearch={setSearch} setPage={setPage} />
          <Stack flexDirection={"row"} gap={1} alignItems={"center"}>
            <Button
              buttonName="Filter"
              onClick={toggleDrawer}
              startIcon={<VscFilter />}
              color="white"
              size="small"
            />
            <SalesOrderFilter
              openDrawer={openDrawer}
              toggleDrawer={toggleDrawer}
              formik={formik}
            />
          </Stack>
        </Stack>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ cursor: "pointer" }}> Order No. </TableCell>
              <TableCell style={{ cursor: "pointer" }}> User Name </TableCell>
              <TableCell style={{ cursor: "pointer" }}> Tax </TableCell>
              <TableCell style={{ cursor: "pointer" }}>
                Payable Amount
              </TableCell>

              <TableCell style={{ cursor: "pointer" }}>Order Status</TableCell>
              <TableCell style={{ cursor: "pointer" }}>
                Payment Status
              </TableCell>
              <TableCell
                style={{ cursor: "pointer" }}
                onClick={() => handleSortClick("purchaseDate", sortDate)}
              >
                Purchase Date
                {sortDate ? (
                  <FaArrowUp style={{ color: "#adadad" }} />
                ) : (
                  <FaArrowDown style={{ color: "#adadad" }} />
                )}
              </TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {console.log("purchaseOrderData", purchaseOrderData)}
            {purchaseOrderData &&
              purchaseOrderData.length > 0 &&
              purchaseOrderData?.map((data, i) => {
                return (
                  <TableRow
                    key={i}
                    onClick={() =>
                      navigate("/view-sales-invoice")
                    }
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      cursor: "pointer",
                    }}
                  >
                    <TableCell className="table-hover">
                      {data?.orderNumber}
                    </TableCell>
                    <TableCell className="table-hover">
                      {data?.firstname + " " + data?.lastname}
                    </TableCell>

                    <TableCell className="table-hover">
                      {data?.taxValue}
                    </TableCell>
                    <TableCell className="table-hover">
                      {data?.totalAmount}
                    </TableCell>

                    <TableCell
                      className="table-hover"
                      onClick={(e) => e.stopPropagation()}
                    >
                      {console.log("--1", data.orderStatus)}
                      <Select
                        name="order-status"
                        value={data.orderStatus}
                        // onChange={(e) => console.log("000-", e)}
                        onChange={(e, child) =>
                          setIsModalOpen({
                            id: e.target.value,
                            name: child.props.name,
                            orderId: data.id,
                            open: true,
                            currentComponent: "OrderStatusChange",
                            para: "",
                            head: "Order Status",
                          })
                        }
                        selectList={saleOrderStatus}
                      />
                      {/* <Chip
                        label="Pending"
                        color={data?.orderStatus == "0" ? "warning" : "success"}
                      /> */}
                    </TableCell>

                    <TableCell className="table-hover">
                      <Chip label="Success" color={"success"} />
                    </TableCell>

                    <TableCell>
                      {moment(data?.purchaseDate).format("DD-MM-YYYY")}
                    </TableCell>

                    <TableCell
                      onClick={(e) => e.stopPropagation()}
                      sx={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <DropdownMenu.Root>
                        <DropdownMenu.Trigger className="chatDropDown">
                          <MenuButton
                            icon={
                              <MdMoreVert
                                style={{ color: "#000", fontSize: "24px" }}
                              />
                            }
                            width="35px"
                            height="38px"
                          />
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content
                          className="chatDropDownContent"
                          align="end"
                        >
                          <DropdownMenu.Item
                            className="chatDropDownMenu"
                            onClick={() =>
                              navigate("/view-sales-invoice")
                            }
                          >
                            <Stack
                              flexDirection={"row"}
                              gap={1}
                              alignItems={"center"}
                            >
                              <IoEyeOutline style={{ fontSize: "20px" }} />
                              <Box sx={{ fontSize: "16px" }}>View</Box>
                            </Stack>
                          </DropdownMenu.Item>
                          {/* <DropdownMenu.Item className='chatDropDownMenu' onClick={() => navigate('/update-user', {
                          state: {
                            id: data?.id,
                          }
                        })}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><AiOutlineEdit style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>Edit</Box></Stack>
                        </DropdownMenu.Item> */}
                          {/* <DropdownMenu.Item className='chatDropDownMenu' onClick={() =>
                          setIsModalOpen({ open: true, currentComponent: "delete", head: "Delete User", para: "Are you sure you want to Delete this User?", id: data?.id })}>
                          <Stack flexDirection={'row'} gap={1} alignItems={'center'}><HiOutlineTrash style={{ fontSize: '20px' }} /><Box sx={{ fontSize: '16px' }}>Delete</Box></Stack>
                        </DropdownMenu.Item> */}
                        </DropdownMenu.Content>
                      </DropdownMenu.Root>
                    </TableCell>
                  </TableRow>
                );
              })}

            {purchaseOrderData === null ? (
              <TableRow>
                <TableCell
                  sx={{
                    color: "silver",
                    textAlign: "center",
                    paddingTop: "90px",
                    borderBottom: "none",
                    fontSize: "30px",
                  }}
                  colSpan="6"
                >
                  No records to display
                </TableCell>
              </TableRow>
            ) : purchaseOrderData && purchaseOrderData.length === 0 ? (
              <TableRowsLoader rowsNum={10} />
            ) : (
              ""
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {
        // Conditionally render Pagination component if purchaseOrderData is not null
        purchaseOrderData != null && (
          <Pagination
            totalData={totalData} // Total number of data items
            page={page} // Current page number
            setPage={setPage} // Function to update current page number
            rowsPerPage={rowsPerPage} // Number of rows per page
            setRowsPerPage={setRowsPerPage} // Function to update number of rows per page
          />
        )
      }
      <Modal
        modalOpen={isModalOpen} // Indicates whether the modal is open or closed
        // handelClick={changeStatus} // Handler function to change the status of the modal
        handleClose={() =>
          // Handler function to close the modal and reset its state
          setIsModalOpen({
            open: false,
            id: "",
            currentComponent: "",
            para: "",
            head: "",
          })
        }
        callBackHandler={callBackHandler}
      />
    </div>
  );
};

export default SalesInvoice;
