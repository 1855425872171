import { Warehouse } from "@mui/icons-material";
import * as Yup from "yup";

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  password: Yup.string()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters."),
});

export const addProductValidationSchema = Yup.object().shape({
  productName: Yup.string().required("Product Name is required."),
  brand: Yup.string().required("Brand is required."),
  sku: Yup.string().required("SKU is required."),
  // productPrice: Yup.string()
  //   .required("Product Price is required.")
  //   .matches(
  //     /^\d{1,9}(\.\d{1,5})?$/,
  //     "Price must be a number with a maximum of 6 digits."
  //   ),
  // mrp: Yup.string()
  //   .required("Product Price is required.")
  //   .matches(
  //     /^\d{1,9}(\.\d{1,5})?$/,
  //     "Price must be a number with a maximum of 6 digits."
  //   ),

  tax: Yup.string().required("Tax is required."),
  category: Yup.string().required("Category is required."),
  subCategory: Yup.string().required("Sub Category is required."),
  // pieces: Yup.string()
  //   .required("Pieces is required.")
  //   .matches(/^[0-9]*$/, "Pieces must be a number.")
  //   .max(
  //     5,
  //     "Attention! Price should have a max of 5 digits. Keep it in check!"
  //   ),
  warehouseId: Yup.string().required("Warehouse is required."),
  // minQuantity: Yup.string()
  //   .required("Minimum Quantity is required.")
  //   .matches(/^[0-9]*$/, "Minimum Quantity must be numeric.")
  //   .max(
  //     5,
  //     "Attention! Quantity should have a max of 5 digits. Keep it in check!"
  //   ),
  // maxQuantity: Yup.string()
  //   .required("Maximum Quantity is required.")
  //   .matches(/^[0-9]*$/, "Maximum Quantity must be numeric.")
  //   .max(
  //     5,
  //     "Attention! Quantity should have a max of 5 digits. Keep it in check!"
  //   ),
});

export const addCategoryValidationSchema = Yup.object().shape({
  categoryName: Yup.string().required("Category Name is required."),
});
export const addFeatureValidationSchema = Yup.object().shape({
  name: Yup.string().required("Category Name is required."),
});
export const addCountryValidationSchema = Yup.object().shape({
  countryName: Yup.string().required("Country Name is required."),
});

export const addStateValidationSchema = Yup.object().shape({
  stateName: Yup.string().required("State Name is required."),
  countryId: Yup.string().required("Country selection is required."),
});

export const addCityValidationSchema = Yup.object().shape({
  cityName: Yup.string().required("City Name is required."),
  stateId: Yup.string().required("State selection is required."),
  countryId: Yup.string().required("Country selection is required."),
});

export const addSubCategoryValidationSchema = Yup.object().shape({
  subCategoryName: Yup.string().required("Sub Category Name is required."),
  categoryId: Yup.string().required("Category is required."),
});

export const addDiscountValidationSchema = Yup.object().shape({
  title: Yup.string().required("Sub Category Name is required."),
  value: Yup.string()
    .required("Value is required.")
    .matches(/^[0-9]*$/, "Value must be numeric."),
});

export const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("New Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  confirmpass: Yup.string()
    .required("Confirm Password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/,
      "Password must have at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character."
    ),
  currentPassword: Yup.string().required("Password is required."),
});

export const addAdminValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  role: Yup.string().required("Role is required."),
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
});

export const roleAddValidationSchema = Yup.object().shape({
  roleName: Yup.string().required("Role Name is required."),
});

export const addCouponValidationSchema = Yup.object().shape({
  couponCode: Yup.string().required("Coupon Code is required."),
  title: Yup.string().required("Title is required."),
  description: Yup.string().required("Description is required."),
  discountType: Yup.string().required("Discount Type is required."),
  couponType: Yup.string().required("Coupon Type is required."),
  discountAmount: Yup.string()
    .required("Discount Amount is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Discount Amount must be a number with a maximum of 6 digits."
    ),
  startDate: Yup.string().required("Start Date is required."),
  endDate: Yup.string().required("End Date is required."),
  minAmount: Yup.string()
    .required("Minimum Amount is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Minimum Amount must be a number with a maximum of 6 digits."
    ),
  maxAmount: Yup.string()
    .required("Maximum Amount is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Maximum Amount must be a number with a maximum of 6 digits."
    ),
  maxUseCouponLimit: Yup.string()
    .required("Maximum Use Coupon Limit is required.")
    .matches(/^[0-9]*$/, "Maximum Use Coupon Limit must be a number.")
    .max(
      6,
      "Maximum Use Coupon Limit should have a max of 6 digits. Keep it in check!"
    ),

  perUserUsage: Yup.string()
    .required("Per User Usage Limit is required.")
    .matches(/^[0-9]*$/, "Per User Usage Limit must be a number.")
    .max(
      6,
      "Per User Usage Limit should have a max of 6 digits. Keep it in check!"
    ),

  countCheck: Yup.string()
    .optional()
    .when(
      ["maxUseCouponLimit", "perUserUsage"],
      ([maxUseCouponLimit, perUserUsage], schema) => {
        console.log("maxUseCouponLimit", maxUseCouponLimit);
        console.log("perUserUsage", maxUseCouponLimit < perUserUsage);
        return maxUseCouponLimit < perUserUsage
          ? schema.required(
            "Per User Usage Limit Not Greater Than Maximum Use Coupon Limit."
          )
          : schema;
      }
    ),

  // perUserUsage: Yup.string().required('Per User Usage Limit is required.').
  //   when('maxUseCouponLimit', (maxUseCouponLimit, schema) => {
  //     console.log("maxUseCouponLimit", this.parent.maxUseCouponLimit);
  //     return maxUseCouponLimit < 2 ? schema.required('Per User Usage Limit Not Greater Than Maximum Use Coupon Limit.') : schema;
  //   }),
});

export const addCompanyValidationSchema = Yup.object().shape({
  companyName: Yup.string().required("Company Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  phoneNumber: Yup.string()
    .required("Phone Number is required.")
    .matches(/^[0-9]*$/, "Phone Number must be numeric.")
    .max(10, "Phone Number should have a max of 10 digits. Keep it in check!")
    .min(10, "Phone Number should have a min of 10 digits. Keep it in check!"),
  vendorName: Yup.string()
    .required("Vendor Name is required.")
    .matches(/^[a-zA-Z\s]*$/, "Vendor Name must be a character."),
  gstNumber: Yup.string()
    .required("GST Number is required.")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "GST Number is not valid."
    ),
  panNumber: Yup.string()
    .required("PAN Number is required.")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "PAN Number is not valid."),
  addressLine1: Yup.string().required("Address Line 1 is required."),
  stateId: Yup.string().required("State is required."),
  cityId: Yup.string().required("City is required."),
  zipCode: Yup.string()
    .required("Zip Code is required.")
    .matches(/^[0-9]*$/, "Zip Code must be numeric.")
    .max(6, "Zip Code should have a max of 6 digits. Keep it in check!"),
});

export const addWarehouseValidationSchema = Yup.object().shape({
  warehouseName: Yup.string().required("Warehouse Name is required."),
  companyName: Yup.string().required("Company Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
  warehouseKey: Yup.string().required("Warehouse Key is required."),
  phoneNumber: Yup.string()
    .required("Phone Number is required.")
    .matches(/^[0-9]*$/, "Phone Number must be numeric.")
    .max(10, "Phone Number should have a max of 10 digits. Keep it in check!")
    .min(10, "Phone Number should have a min of 10 digits. Keep it in check!"),
  ownerName: Yup.string()
    .required("Owner Name is required.")
    .matches(/^[a-zA-Z\s]*$/, "Owner Name must be a character."),
  gstNumber: Yup.string()
    .required("GST Number is required.")
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/,
      "GST Number is not valid."
    ),
  panNumber: Yup.string()
    .required("PAN Number is required.")
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/, "PAN Number is not valid."),
  addressLine1: Yup.string().required("Address Line 1 is required."),
  // stateId: Yup.string().required('State is required.'),
  // cityId: Yup.string().required('City is required.'),
  zipCode: Yup.string()
    .required("Zip Code is required.")
    .matches(/^[0-9]*$/, "Zip Code must be numeric.")
    .max(6, "Zip Code should have a max of 6 digits. Keep it in check!"),
});

export const useeAddressValidationSchema = Yup.object().shape({
  addressLine1: Yup.string().required("Address Line 1 is required."),

  zipCode: Yup.string()
    .required("Zip Code is required.")
    .matches(/^[0-9]*$/, "Zip Code must be numeric.")
    .max(6, "Zip Code should have a max of 6 digits. Keep it in check!")
    .min(6, "Zip Code should have a min of 6 digits. Keep it in check!"),
});

export const purchaseOrderValidationSchema = Yup.object().shape({
  vendorId: Yup.string().required("Vendor selection is required."),
  venderIncNo: Yup.string().required("Invoice Number is required."),
  purchaseDate: Yup.string().required("Purchase Date is required."),
  // tax: Yup.string().required('Tax is required.')
  //   .matches(
  //     /^\d{1,6}(\.\d{1,5})?$/,
  //     'CGST must be a number with a maximum of 6 digits.'
  //   ),
  // taxValue: Yup.string().required('Tax Value is required.')
  //   .matches(
  //     /^\d{1,6}(\.\d{1,5})?$/,
  //     'Tax Value must be a number with a maximum of 6 digits.'
  //   ),
  // subTotal: Yup.string().required('Subtotal is required.')
  //   .matches(
  //     /^\d{1,6}(\.\d{1,5})?$/,
  //     'Subtotal must be a number with a maximum of 6 digits.'
  //   ),
  discountAmount: Yup.string()
    .optional("Discount Amount is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Discount Amount must be a number with a maximum of 6 digits."
    ),
  adjustment: Yup.string()
    .optional("Adjustment is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Adjustment must be a number with a maximum of 6 digits."
    ),
  // payableAmount: Yup.string().required('Payable Amount is required.')
  //   .matches(
  //     /^\d{1,6}(\.\d{1,5})?$/,
  //     'Payable Amount must be a number with a maximum of 6 digits.'
  //   ),

  //if payment value true then payment type required and then payment type value 1 then transaction id required payment type 2 then cheque number reuired
  payment: Yup.string(),
  //paymentType: Yup.string().when('payment', (payment, schema) => {
  //return payment == 'true' ? schema.required('Payment Type is required.') : schema;
  //}
  // ),
  transactionId: Yup.string().when("paymentType", ([paymentType], schema) => {
    return paymentType == "Online"
      ? schema.required("Transaction ID is required.")
      : schema;
  }),
  chequeNumber: Yup.string().when("paymentType", ([paymentType], schema) => {
    return paymentType == "Cheque"
      ? schema.required("Cheque Number is required.")
      : schema;
  }),

  paymentDate: Yup.string().when("paymentType", ([paymentType], schema) => {
    console.log("paymentType", paymentType);
    return paymentType != undefined
      ? schema.required("Payment Date is required.")
      : schema;
  }),

  paymentAmount: Yup.string().when("paymentType", ([paymentType], schema) => {
    return paymentType != undefined
      ? schema.required("Payment Amount is required.")
      : schema;
  }),
});

export const purchaseOrderPaymentValidationSchema = Yup.object().shape({
  payment: Yup.string(),
  paymentType: Yup.string().required("Payment Type is required."),

  transactionId: Yup.string().when("paymentType", ([paymentType], schema) => {
    return paymentType == "Online"
      ? schema.required("Transaction ID is required.")
      : schema;
  }),
  chequeNumber: Yup.string().when("paymentType", ([paymentType], schema) => {
    return paymentType == "Cheque"
      ? schema.required("Cheque Number is required.")
      : schema;
  }),

  paymentDate: Yup.string().required("Payment Date is required."),

  paymentAmount: Yup.string()
    .required("Payment Amount is required.")
    .matches(
      /^\d{1,11}(\.\d{1,5})?$/,
      "Payment Amount must be a number with a maximum of 9 digits."
    ),
});

export const profileUpdateValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required."),
  lastName: Yup.string().required("Last Name is required."),
  email: Yup.string()
    .email("Invalid email address.")
    .required("Email is required."),
});

export const addAppointmentValidationSchema = Yup.object().shape({
  user: Yup.string().required("User selection is required."),
  date: Yup.string().required("Date selection is required."),
  time: Yup.string().required("Time slot selection is required."),
  message: Yup.string().required("Message is required."),
  type: Yup.string().required("Type selection is required."),
});

export const addPurchaseOrderValidationSchema = Yup.object().shape({
  userId: Yup.string().required("User selection is required."),
});

export const addTaxValidationSchema = Yup.object().shape({
  name: Yup.string().required("Tax Name is required."),
  taxType: Yup.string().required("Tax Type is required."),
  taxValue: Yup.string()
    .required("Tax Value is required.")
    .matches(
      /^\d{1,6}(\.\d{1,5})?$/,
      "Tax Value must be a number with a maximum of 6 digits."
    ),
  label: Yup.string().required("Label is required."),
});

export const addSlotValidationSchema = Yup.object().shape({
  duration: Yup.string()
    .required("Duration is required.")
    .matches(/^(0[1-9]|[1-9][0-9])$/, "Duration must be between 1 to 99."),
  duration1: Yup.string()
    .required("Duration is required.")
    .matches(/^(0[1-9]|[1-9][0-9])$/, "Duration must be between 1 to 99."),
});
export const addUomValidationSchema = Yup.object().shape({
  name: Yup.string().required('Tax Name is required.'),
});